//新增项目合同
<template>
  <base-dialog
    :visible.sync="visibleState"
    :showFooter="false"
    :title="dialogTitle"
  >
    <base-form
      :componentList="addFrom"
      :showBtns="false"
      :formAttrs="{
        model: addQueryParas,
        labelWidth: '120px',
      }"
      class="formStyle"
      ref="addfromdata"
    />
    <template slot="footer">
      <base-button label="提 交" @click="submit" />
      <base-button label="关 闭" type="default" @click="visibleState = false" />
    </template>
  </base-dialog>
</template>
<script>
import { addFromConfig } from '../utils/add-contract-config'
import { assetcontractApi } from '@/api/assetApi'
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseForm from '@/components/common/base-form//base-form'
import { formatDate } from '@/utils/auth/common'
import { getDictLists } from '@/filters/fromDict'
import { supplierApi } from '@/api/companyApi'

export default {
  props: {
    visible: Boolean,
    keyId: String,
    submitType: String,
    dropOptions: Array,
    contractId: String
  },
  data () {
    return {
      addQueryParas: {},
      showInput: true,
      dialogTitle: '',
      companyList: []
    }
  },
  components: { BaseDialog, BaseButton, BaseForm },
  computed: {
    addFrom () {
      return addFromConfig(this)
    },
    visibleState: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    contractTypeList () {
      return getDictLists('CONTRACT_TYPE')
    },
    wetherList () {
      return getDictLists('SETTLEMENT_TYPE')
    }
  },
  watch: {
    visibleState (val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.addfromdata.resetFields()
          this.detail()
        })
      }
    }
  },
  methods: {
    // 修改或新增数据初始化
    async detail () {
      this.addQueryParas = {
        settlementFlag: '0'
      }
      if (this.submitType === 'add') {
        // 新增的情况
        this.showInput = true
        this.dialogTitle = '新增项目合同信息'
        this.getCompanyList()
      } else if (this.submitType === 'updata') {
        // 修改的情况
        this.dialogTitle = '修改项目合同信息'
        const res = await assetcontractApi
          .contractDetail({ keyId: this.keyId, contractId: this.contractId })
          .then((res) => {
            return res
          })
        res.data.contractType === '工程类'
          ? (this.showInput = false)
          : (this.showInput = true)
        const [obj2] = this.contractTypeList.filter(
          (item) => item.dictName === res.data.contractType
        )
        res.data.contractTypeId = obj2 ? obj2.dictId : ''
        res.data.settlementFlag = res.data.settlementFlag !== undefined ? String(res.data.settlementFlag) : ''
        // res.data.paymentTotalPercent = res.data.paymentTotalPercent < 0 ? '' : res.data.paymentTotalPercent
        // res.data.effectiveSignAmount = res.data.effectiveSignAmount < 0 ? '' : res.data.effectiveSignAmount
        // res.data.supplementContractAmount = res.data.supplementContractAmount < 0 ? '' : res.data.supplementContractAmount
        // res.data.settlementPercent = res.data.settlementPercent < 0 ? '' : res.data.settlementPercent
        // res.data.paymentPercent = res.data.paymentPercent < 0 ? '' : res.data.paymentPercent

        this.addQueryParas = res.data
        if (res.data.effectiveSignAmount === 0) {
          this.addQueryParas.effectiveSignAmount = ''
        } else {
          this.addQueryParas.effectiveSignAmount = res.data.effectiveSignAmount
        }
        this.getCompanyList(res.data.xmgsName, true)
      }
      this.visibleAdd = true
    },
    // 提交新增
    submit () {
      this.$refs.addfromdata.validate((valid) => {
        if (valid) {
          // 数据处理
          if (this.addQueryParas.signTime) {
            this.$set(this.addQueryParas, 'signTime', formatDate(this.addQueryParas.signTime, 'YY-MM-DD'))
          }
          this.addQueryParas.paymentTotalPercent = this.addQueryParas.paymentTotalPercent === '' ? null : this.addQueryParas.paymentTotalPercent
          this.addQueryParas.supplementContractAmount = this.addQueryParas.supplementContractAmount === '' ? null : this.addQueryParas.supplementContractAmount
          const data = this.addQueryParas
          if (this.submitType === 'add') {
            // 新增的接口
            assetcontractApi.addInfo(data).then((res) => {
              console.log(res)
              if (res.code === '0') {
                this.success('新增成功')
                this.$emit('handleFilter', '')
                this.visibleState = false
              } else {
                this.error('新增失败')
              }
            })
          } else if (this.submitType === 'updata') {
            // 修改的接口
            assetcontractApi.updataInfo(data).then((res) => {
              if (res.code === '0') {
                this.$emit('handleFilter', '')
                this.success('修改成功')
                this.visibleState = false
              } else {
                this.error('修改失败')
              }
            })
          }
        }
      })
    },
    // 合同类别
    changeContractType (data) {
      const [obj] = this.contractTypeList.filter(
        (item) => item.dictId === data
      )
      this.addQueryParas.contractType = obj ? obj.dictName : ''
      obj.dictId === '0' ? (this.showInput = false) : (this.showInput = true)
    },
    // 申请主体
    changeGys (data) {
      const [obj] = this.dropOptions.filter((item) => item.keyId === data)
      this.addQueryParas.gysName = obj ? obj.enterpriseName : ''
    },
    // 项目公司下拉选择项
    getCompanyList (val, first) {
      if (val && !(/^[a-zA-Z\d-_\u4e00-\u9fa5]*$/.test(val))) {
        val = val.replace(/[^a-zA-Z\d-_\u4e00-\u9fa5]/g, '')
      }
      supplierApi.getPage({ pageIndex: 1, pageSize: 25, enterpriseLabel: 2, enterpriseName: val }).then(res => {
        this.companyList = res.data.records
        if (first && !res.data.records?.length) {
          this.getCompanyList()
        }
      })
    },
    changeXmgs (val) {
      const item = this.companyList.find(s => s.keyId === val)
      this.$set(this.addQueryParas, 'xmgsName', item?.enterpriseName || '')
      this.$set(this.addQueryParas, 'xmgsShortCode', item?.enterpriseShortCode || '')
      !val && this.getCompanyList()
    }
  }
}
</script>

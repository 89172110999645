import Amountinput from '@/components/input/amount-input/amount-input'
// import supplierSelect from '@/components/packages/supplier-select/supplier-select'
import BaseSelect from '@/components/common/base-select/base-select'
import NewBaseSelect from '@/components/common/base-select/new-base-select'

// 新增
export const addFromConfig = (content) => {
  const span = 8
  const startDatePicker = {
    disabledDate (time) {
      return time.getTime() < new Date('1990-01-01')
    }
  }
  // 合同金额
  function validContractAmount (r, v, c) {
    if (!v && v !== 0) {
      c(new Error('请输入合同金额'))
    } else if ((v * 1 < content.addQueryParas.effectiveSignAmount * 1 && content.addQueryParas.effectiveSignAmount)) {
      c(new Error('不能小于有效金额'))
    } else if ((v < content.addQueryParas.paidAmount * 1 && content.addQueryParas.paidAmount)) {
      c(new Error('不能小于已付金额'))
    } else {
      c()
    }
  }
  // 有效金额
  function validEffectiveSignAmount (r, v, c) {
    if (v && v !== 0) {
      if (v * 1 > content.addQueryParas.contractAmount * 1) {
        c(new Error('不能大于合同金额'))
      } else if (v * 1 < content.addQueryParas.paidAmount * 1) {
        c(new Error('不能小于已付金额'))
      } else {
        c()
      }
    } else {
      c()
    }
  }
  // 已付金额
  function validPaidAmount (r, v, c) {
    if (!v && v !== 0) {
      c(new Error('请输入已付金额'))
    } else if ((v * 1 > content.addQueryParas.effectiveSignAmount * 1 && content.addQueryParas.effectiveSignAmount)) {
      c(new Error('不能大于合同有效金额'))
    } else if (v * 1 > content.addQueryParas.contractAmount * 1 && content.addQueryParas.contractAmount) {
      c(new Error('不能大于合同金额'))
    } else {
      c()
    }
  }
  // 完成工程产值
  function validatorOutputTotalAmount (r, v, c) {
    if (!v && v !== 0) {
      c(new Error('请输入完成工程产值'))
    } else {
      c()
    }
    // else if ((v * 1 <= content.addQueryParas.paidAmount * 1)) {
    //   c(new Error('必须大于已付金额'))
    // }
  }
  return [
    {
      label: '供应商名称',
      prop: 'gysId',
      span,
      tag: NewBaseSelect,
      rules: [{ required: true, message: '请选择供应商名称', trigger: 'change' }],
      attrs: {
        placeholder: '请选择',
        options: content.dropOptions,
        selectedField: ['keyId', 'enterpriseName'],
        filterable: true
      },
      on: {
        change: (data) => {
          content.changeGys(data)
        }
      }
    },
    // {
    //   label: '项目公司',
    //   prop: 'xmgsName',
    //   span,
    //   attrs: {
    //     placeholder: '请输入'
    //   }
    // },
    {
      label: '项目公司',
      prop: 'xmgsId',
      span,
      tag: NewBaseSelect,
      rules: [{ required: true, message: '请选择项目公司', trigger: 'change' }],
      attrs: {
        placeholder: '请选择',
        options: content.companyList,
        selectedField: ['keyId', 'enterpriseName'],
        filterable: true,
        remote: true,
        remoteMethod: content.getCompanyList
      },
      on: {
        change: (data) => {
          content.changeXmgs(data)
        }
      }
    },
    {
      label: '项目公司简码',
      prop: 'xmgsShortCode',
      rules: [{ required: true, message: '请输入项目公司简码', trigger: 'blur' }],
      span,
      attrs: {
        placeholder: '请输入',
        maxLength: 50
      },
      isHidden: !content.addQueryParas.xmgsName
    },
    {
      label: '区域',
      prop: 'area',
      span,
      attrs: {
        placeholder: '请输入',
        maxLength: 50
      }
    },
    {
      label: '项目编号',
      prop: 'projectCode',
      span,
      attrs: {
        placeholder: '请输入'
      }
    },
    {
      label: '项目名称',
      prop: 'projectName',
      span,
      attrs: {
        placeholder: '请输入'
      }
    }, {
      label: '所属城市公司',
      prop: 'city',
      span,
      attrs: {
        placeholder: '请输入'
      }
    }, {
      label: '合同编号',
      prop: 'contractCode',
      rules: [{ required: true, message: '请输入合同编号', trigger: 'blur' }],
      span,
      attrs: {
        placeholder: '请输入'
      }
    }, {
      label: '合同名称',
      prop: 'contractName',
      span,
      rules: [{ required: true, message: '请输入合同名称', trigger: 'blur' }],
      attrs: {
        placeholder: '请输入'
      }
    }, {
      label: '合同类别',
      prop: 'contractTypeId',
      rules: [{ required: true, message: '请选择合同类别', trigger: 'change' }],
      tag: BaseSelect,
      span,
      attrs: {
        placeholder: '请选择',
        clearable: true,
        options: content.contractTypeList,
        selectedField: ['dictId', 'dictName']
      },
      on: {
        change: (data) => {
          content.changeContractType(data)
        }
      }
    }, {
      label: '合同签署时间',
      prop: 'signTime',
      tag: 'el-date-picker',
      span,
      rules: [{ required: false, message: '请选择合同签署时间', trigger: 'blur' }],
      attrs: {
        placeholder: '请选择',
        editable: false,
        clearable: true,
        pickerOptions: startDatePicker
      }
    },
    {
      label: '累计付款比例(%)',
      prop: 'paymentTotalPercent',
      span,
      attrs: {
        type: 'BL',
        placeholder: '请输入'
      },
      tag: Amountinput

    }, {
      label: '合同金额(元)',
      prop: 'contractAmount',
      span,
      rules: [{ required: true, validator: validContractAmount, trigger: 'blur' }],
      attrs: {
        type: '1000Y',
        placeholder: '请输入'
      },
      tag: Amountinput
    }, {
      label: '合同有效签约金额(元)',
      prop: 'effectiveSignAmount',
      rules: [{ required: false, validator: validEffectiveSignAmount, trigger: 'blur' }],
      span,
      attrs: {
        type: '1000Y',
        placeholder: '请输入'
      },
      tag: Amountinput
    }, {
      label: '补充协议调整金额(元)',
      prop: 'supplementContractAmount',
      span,
      attrs: {
        type: '1000Y',
        placeholder: '请输入'
      },
      tag: Amountinput
    }, {
      label: '已付金额(元)',
      prop: 'paidAmount',
      span,
      rules: [{ required: true, validator: validPaidAmount, trigger: 'blur' }],
      attrs: {
        type: '1000Y',
        placeholder: '请输入'
      },
      tag: Amountinput
    }, {
      label: '进度款占比(%)',
      prop: 'progressPaymentPercent',
      span,
      attrs: {
        type: 'BL',
        placeholder: '请输入'
      },
      // isHidden: content.showInput,
      tag: Amountinput
    }, {
      label: '结算款占比(%)',
      prop: 'settlementPercent',
      span,
      attrs: {
        type: 'BL',
        placeholder: '请输入'
      },
      // isHidden: content.showInput,
      tag: Amountinput
    }, {
      label: '完成工程产值(元)',
      prop: 'outputTotalAmount',
      span,
      rules: [{ required: true, validator: validatorOutputTotalAmount, trigger: 'blur' }],
      attrs: {
        type: '1000Y',
        placeholder: '请输入'
      },
      // isHidden: content.showInput,
      tag: Amountinput
    }, {
      label: '是否已结算',
      prop: 'settlementFlag',
      span,
      tag: BaseSelect,
      attrs: {
        placeholder: '请选择',
        clearable: false,
        options: content.wetherList,
        selectedField: ['dictId', 'dictName']
      }
    }, {
      label: '结算时间',
      prop: 'settmentTime',
      span,
      tag: 'el-date-picker',
      attrs: {
        placeholder: '请选择',
        clearable: true,
        editable: false,
        pickerOptions: startDatePicker
      }
    }, {
      label: '结算款支付时间',
      prop: 'settmentPaymentTime',
      span,
      tag: 'el-date-picker',
      attrs: {
        placeholder: '请选择',
        clearable: true,
        editable: false,
        pickerOptions: startDatePicker
      }
    }, {
      label: '支付方式',
      prop: 'payType',
      span,
      // tag: ''
      attrs: {
        placeholder: '请输入',
        maxlength: 20
      }
    }, {
      label: '最后申请付款时间',
      prop: 'latestPayTime',
      span,
      tag: 'el-date-picker',
      attrs: {
        placeholder: '请选择',
        clearable: true,
        editable: false,
        pickerOptions: startDatePicker
      }
    }, {
      label: '备注',
      span: 24,
      prop: 'memo',
      rules: [{ required: false, message: '请输入', trigger: 'blur' }],
      attrs: {
        placeholder: '请输入',
        type: 'textarea',
        rows: 5,
        maxLength: 500
      }

    }

  ]
}
